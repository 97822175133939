// Calendar.tsx
import React from 'react';
import { useRecipeStore } from '../state/recipeStore.ts';
import { useMealPlanStore } from '../state/mealPlanStore.ts';
import { useDroppable } from '@dnd-kit/core';

export const DroppableSlot = ({ id, children }: { id: string, children: React.ReactNode }) => {
    const { setNodeRef, isOver } = useDroppable({
      id: id,
    });
  
    return (
      <div 
        ref={setNodeRef} 
        style={{ 
          minHeight: "50px",
          padding: "8px",
          backgroundColor: isOver ? '#e9ecef' : 'transparent',
          border: '2px dashed ' + (isOver ? '#007bff' : 'transparent'),
          borderRadius: '4px',
          transition: 'all 0.2s ease'
        }}
      >
        {children}
      </div>
    );
  };
  
  export const MealSlot = ({ 
    date, 
    mealType, 
    servingSelections, 
    recipes,
  }: {
    date: string,
    mealType: string,
    servingSelections: any[],
    recipes: any,
  }) => {
    const removeServingSelection = useMealPlanStore(state => state.removeServingSelection);
    
    return (
      <DroppableSlot id={`${date}|${mealType}`}>
        <strong>{mealType.charAt(0).toUpperCase() + mealType.slice(1)}:</strong>
        {servingSelections
          .filter((item) => item.date === date && item.meal_name === mealType)
          .map((item) => {
            const recipe = recipes[item.recipe_id];
            if (!recipe) return null;
            
            return (
              <div key={item.id} style={{ 
                display: "flex", 
                alignItems: "flex-start", 
                gap: "0.25rem",
                width: "100%",
                minWidth: 0
              }}>
                <img
                  src={recipe.picture_link}
                  alt={recipe.title}
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "4px"
                  }}
                />
                <div style={{ 
                  display: "flex", 
                  flexDirection: "column",
                  flex: 1,
                  minWidth: 0
                }}>
                  <span style={{ 
                    overflow: "hidden", 
                    textOverflow: "ellipsis", 
                    whiteSpace: "nowrap",
                    width: "100%"
                  }}>
                    {recipe.title}
                  </span>
                  <span style={{ 
                    fontSize: "0.9rem", 
                    color: "#666",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                  }}>
                    {item.servings} serving{item.servings !== 1 ? 's' : ''}
                  </span>
                </div>
                <button 
                  style={{ 
                    padding: "0.2rem 0.5rem", 
                    fontSize: "0.8rem",
                    flexShrink: 0,
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    backgroundColor: "#f8f9fa",
                    cursor: "pointer",
                    transition: "background-color 0.2s ease"
                  }} 
                  onClick={() => removeServingSelection(item.id!)}
                  onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#e9ecef"}
                  onMouseOut={(e) => e.currentTarget.style.backgroundColor = "#f8f9fa"}
                >×</button>
              </div>
            );
          })}
      </DroppableSlot>
    );
  };

const getWeekDates = () => {
    const today = new Date();
    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    return Array.from({ length: 5 }).map((_, index) => {
        const date = new Date(today);
        date.setDate(today.getDate() + index);
        return {
            weekday: weekdays[date.getDay()],
            fullDate: date,
            displayDate: `${date.getMonth() + 1}/${date.getDate()}`
        };
    });
};

interface CalendarProps {
  isLoading: boolean;
}

const Calendar: React.FC<CalendarProps> = ({ isLoading }) => {
  const { recipes } = useRecipeStore();
  const { servingSelections } = useMealPlanStore();

  if (isLoading) {
    return (
      <div style={{ 
        padding: "2rem",
        textAlign: "center",
        color: "#666"
      }}>
        Loading meal plan...
      </div>
    );
  }

  return (
    <div style={{ 
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
      gap: "1rem",
      width: "100%",
      maxWidth: "100%",
      padding: "0 1rem",
      boxSizing: "border-box"
    }}>
      {getWeekDates().map(({ weekday, fullDate, displayDate }) => {
        const dateStr = fullDate.toISOString().slice(0, 10);
        return (
          <div
            key={weekday}
            style={{ 
              border: "1px solid #ddd",
              borderRadius: "6px",
              padding: "0.5rem",
              fontSize: "0.9rem",
              overflow: "hidden" // Prevent content overflow
            }}
          >
            <h4 style={{ margin: "0 0 0.5rem 0" }}>
              {weekday} <span style={{ fontSize: "0.8em", color: "#666" }}>{displayDate}</span>
            </h4>
            <MealSlot 
              date={dateStr}
              mealType="lunch"
              servingSelections={servingSelections}
              recipes={recipes}
            />
            <MealSlot 
              date={dateStr}
              mealType="dinner"
              servingSelections={servingSelections}
              recipes={recipes}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Calendar;