import React, { useState, useEffect } from 'react';
import { ButtonContainer } from './ButtonGrid.tsx';
import API_URL from '../config.js';

interface ThemeScrollProps {
  onThemeClick: (suggestion: string) => void;
  extractEmojiAndText: (str: string) => { emoji: string; text: string };
  startIndex?: number;
}

const ThemeScroll: React.FC<ThemeScrollProps> = ({
  onThemeClick,
  extractEmojiAndText,
  startIndex = 1,
}) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [isLoadingThemes, setIsLoadingThemes] = useState(true);
  const [page, setPage] = useState(startIndex);
  const [hasMore, setHasMore] = useState(true);
  const [searchInput, setSearchInput] = useState('');

  console.log('startIndex', startIndex);

  const loadMore = async () => {
    if (!hasMore || isLoadingThemes) {
      console.log('Skipping load more:', { hasMore, isLoadingThemes });
      return;
    }
    
    console.log('Loading more themes, page:', page);
    setIsLoadingThemes(true);
    
    try {
      const response = await fetch(`${API_URL}/copilot/suggest-themes/initial?page=${page}`);
      const themes: string[] = await response.json();
      
      if (!themes || themes.length === 0) {
        setHasMore(false);
        return;
      }

      setSuggestions(prev => [...prev, ...themes]);
      setPage(prev => prev + 1);
    } finally {
      setIsLoadingThemes(false);
    }
  };

  const fetchInitialThemes = async () => {
    setIsLoadingThemes(true);
    setPage(startIndex);
    
    try {
      const response = await fetch(`${API_URL}/copilot/suggest-themes/initial?page=${startIndex}`);
      const themes: string[] = await response.json();
      
      if (!themes || themes.length === 0) {
        setHasMore(false);
        setSuggestions([
          "Fall Stew",
          "Moroccan Main",
          "Italian Pasta",
          "Vegan Delight",
          "Quick Snacks"
        ]);
        return;
      }

      setSuggestions(themes);
      setPage(startIndex + 1);
    } catch (error) {
      console.error('Error fetching initial themes:', error);
      setSuggestions([
        "Fall Stew",
        "Moroccan Main",
        "Italian Pasta",
        "Vegan Delight",
        "Quick Snacks"
      ]);
    } finally {
      setIsLoadingThemes(false);
    }
  };

  useEffect(() => {
    fetchInitialThemes();
  }, [startIndex]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data === 'REACHED_BOTTOM' && hasMore && !isLoadingThemes) {
        loadMore();
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [page, hasMore, isLoadingThemes]);

  const handleSearch = () => {
    if (searchInput.trim()) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      onThemeClick(searchInput);
      setSearchInput('');
    }
  };

  const suggestionButtons = suggestions.map(suggestion => {
    const { emoji, text } = extractEmojiAndText(suggestion);
    return {
      emoji,
      text,
      onClick: () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        onThemeClick(suggestion);
      },
      customWidth: '120px',
      customHeight: 'auto'
    };
  });

  return (
    <div style={{ 
      width: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      gap: '0',
      paddingBottom: '160px',
      margin: '0.2rem 0 0 0',
      alignItems: 'center'
    }}>
      <div style={{
        position: 'sticky',
        top: '64px',
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: '#f8f9fa',
        padding: '0.25rem',
        borderBottom: '1px solid #eee',
        width: '100%',
        boxSizing: 'border-box'
      }}>
        <div style={{
          display: 'flex',
          gap: '0.25rem',
          maxWidth: '450px',
          margin: '0 auto',
          width: '100%',
          alignItems: 'center',
          padding: '0 0.25rem',
          boxSizing: 'border-box'
        }}>
          <input
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="search-input"
            style={{
              flex: 1,
              padding: '4px 8px',
              fontSize: '13px',
              border: '1px solid #e9ecef',
              borderRadius: '4px',
              backgroundColor: '#fff',
              transition: 'all 0.2s ease',
              outline: 'none',
              width: '100%',
              height: '28px'
            }}
            onFocus={(e) => {
              e.target.style.borderColor = '#007bff';
              e.target.style.boxShadow = '0 0 0 0.15rem rgba(0,123,255,.20)';
            }}
            onBlur={(e) => {
              e.target.style.borderColor = '#e9ecef';
              e.target.style.boxShadow = 'none';
            }}
            placeholder="Search by theme..."
            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          />
          <button
            onClick={handleSearch}
            style={{
              padding: '0 8px',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: '#007bff',
              color: 'white',
              cursor: 'pointer',
              fontSize: '12px',
              fontWeight: '500',
              transition: 'background-color 0.2s ease',
              whiteSpace: 'nowrap',
              boxShadow: '0 1px 1px rgba(0,0,0,0.1)',
              height: '28px'
            }}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
          >
            Search
          </button>
        </div>
      </div>

      <ButtonContainer 
        buttons={suggestionButtons}
        isLoading={isLoadingThemes}
        skeletonCount={10}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '1rem',
          margin: '0 auto',
          backgroundColor: 'rgb(248, 249, 250)',
          borderRadius: '8px',
          boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 4px',
          padding: '1rem',
          width: '100%',
          maxWidth: '800px',
          boxSizing: 'border-box'
        }}
      />
    </div>
  );
};

export default ThemeScroll;