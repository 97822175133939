import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMealPlanStore } from '../state/mealPlanStore.ts';
import { MdInfo } from 'react-icons/md';

const getDefaultImage = (title: string) => 
    `https://via.placeholder.com/300x200?text=${encodeURIComponent(title || 'Recipe')}`;

const extractDomain = (url) => {
    try {
        const { hostname } = new URL(url);
        return hostname;
    } catch (error) {
        console.error("Invalid URL:", error);
        return url;
    }
};

const styles = {
    cardWrapper: {
        position: 'relative' as const,
        width: '100%',
        maxWidth: '300px',
        display: 'block',
        border: '1px solid #ddd',
        borderRadius: '8px',
        transition: 'all 0.2s ease',
        padding: '8px',
    },
    cardWrapperHover: {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f8f9fa',
    },
    recipeContent: {
        display: 'flex',
        flexDirection: 'column' as const,
        height: '100%',
    },
    imageWrapper: {
        width: '100%',
        position: 'relative' as const,
        paddingBottom: '100%',
        marginBottom: '4px',
        overflow: 'hidden',
        borderRadius: '4px',
        flexShrink: 0,
    },
    image: {
        position: 'absolute' as const,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover' as const,
    },
    recipeText: {
        width: '100%',
        overflow: 'hidden',
    },
    title: {
        fontWeight: 'bold',
        marginBottom: '8px',
        fontSize: '1.1em',
        lineHeight: 1.3,
        height: 'auto',
        maxHeight: '2.6em',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical' as const,
    },
    metadata: {
        fontSize: '0.7em',
        color: '#666',
        width: '100%',
    },
    sourceUrl: {
        margin: '0 0 2px 0',
        whiteSpace: 'nowrap' as const,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
    },
    stats: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(60px, 1fr))',
        gap: '2px',
        width: '100%',
    },
    statsText: {
        margin: 0,
        whiteSpace: 'nowrap' as const,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
    },
    hoverButtons: {
        position: 'absolute' as const,
        top: '10px',
        right: '10px',
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '8px',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        padding: '4px',
        borderRadius: '4px',
    },
    actionButton: {
        padding: '6px 12px',
        borderRadius: '4px',
        border: 'none',
        cursor: 'pointer',
        fontSize: '14px',
        transition: 'all 0.2s ease',
        textAlign: 'center' as const,
        minWidth: '80px',
        maxWidth: '90%',
    },
    addButton: {
        backgroundColor: '#4CAF50',
        color: 'white',
    },
    addButtonDisabled: {
        backgroundColor: '#A5D6A7',
        cursor: 'default',
    },
    detailsButton: {
        backgroundColor: '#2196F3',
        color: 'white',
        padding: '6px 8px',
        textDecoration: 'none',
    },
    detailsButtonHover: {
        backgroundColor: '#1976D2',
    },
    detailsIcon: {
        fontSize: '18px',
    },
};

const RecipeSearchResult = ({ recipe }) => {
    const [imgSrc, setImgSrc] = useState(recipe.picture_link);
    const [isHovered, setIsHovered] = useState(false);
    const { prepSelections, addToMealPrepPlan, isLoading, error } = useMealPlanStore();
    const [added, setAdded] = useState(
        prepSelections.some(s => s.recipe_id === recipe.id)
    );

    useEffect(() => {
        setAdded(prepSelections.some(s => s.recipe_id === recipe.id));
    }, [prepSelections, recipe.id]);

    const handleAddToMealPlan = async () => {
        if (added) return;
        
        try {
            await addToMealPrepPlan(recipe.id, recipe.servings || 1);
            setAdded(true);
        } catch (error) {
            // Error handling is done in store
        }
    };

    const handleImageError = () => {
        setImgSrc(getDefaultImage(recipe.title));
    };

    return (
        <div 
            style={{
                ...styles.cardWrapper,
                ...(isHovered ? styles.cardWrapperHover : {})
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div style={styles.recipeContent}>
                <div style={styles.imageWrapper}>
                    <img 
                        style={styles.image}
                        src={imgSrc} 
                        alt={`${recipe.title}`}
                        onError={handleImageError}
                    />
                </div>
                <div style={styles.recipeText}>
                    <div style={styles.title}>
                        {recipe.title}
                    </div>
                    <div style={styles.metadata}>
                        <p style={styles.sourceUrl}>{extractDomain(recipe.source_url)}</p>
                        <div style={styles.stats}>
                            <p style={styles.statsText}>Servings: {recipe.servings}</p>
                            <p style={styles.statsText}>Prep: {recipe.preparation_minutes}m</p>
                            <p style={styles.statsText}>Cook: {recipe.cooking_minutes}m</p>
                        </div>
                    </div>
                </div>
                
                <div style={styles.hoverButtons}>
                    <button 
                        style={{
                            ...styles.actionButton,
                            ...styles.addButton,
                            ...(isLoading || added ? styles.addButtonDisabled : {})
                        }}
                        onClick={handleAddToMealPlan}
                        disabled={isLoading || added}
                    >
                        {isLoading ? '...' : added ? '✓ Added to Meal Plan' : '+'}
                        {!isLoading && !added && ' Add to Meal Plan'}
                    </button>
                    <Link 
                        to={`/recipe-view/${recipe.id}`}
                        style={{
                            ...styles.actionButton,
                            ...styles.detailsButton,
                            ...(isHovered ? styles.detailsButtonHover : {})
                        }}
                        title="View Details"
                    >
                        <MdInfo style={styles.detailsIcon} />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default RecipeSearchResult;