import React, { useCallback, useState } from 'react';
import { addItemsToCart } from '../api/api.ts';
import styles from './AddToCartButton.module.css';
import { Recipe } from '../state/recipeStore.ts';
import { useAuthStore } from '../state/authStore.ts';
import { useSelectionStore } from '../state/selectionStore.ts';

interface AddToCartButtonProps {
  recipe: Recipe;
  modality: string;
}

const AddToCartButton: React.FC<AddToCartButtonProps> = ({ recipe, modality }) => {
  const hasKrogerToken = useAuthStore(state => state.krogerToken) !== null;
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const openKrogerCart = () => {
    window.open('https://www.kroger.com/cart', '_blank');
  };

  const addToCart = useCallback(async () => {
    if (!hasKrogerToken) {
      setError('Please login to add items to cart');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const selectionStore = useSelectionStore.getState();
      await selectionStore.saveSelections(recipe.id);

      const items = Object.values(recipe.recipeIngredients)
        .filter(ingredient => 
          selectionStore.getSelectedForPurchase(recipe.id, ingredient.id)
        )
        .map(ingredient => {
          const selectedProduct = selectionStore.getSelectedProduct(recipe.id, ingredient.id);
          
          if (!selectedProduct) {
            throw new Error(`No product selected for ingredient ${ingredient.id}`);
          }

          const quantity = selectionStore.getSelectedQuantity(recipe.id, ingredient.id);

          return {
            quantity,
            upc: selectedProduct.id,
            modality
          };
        });

      if (items.length === 0) {
        setError('No items selected for purchase');
        return;
      }

      const krogerAuthToken = await useAuthStore.getState().getValidKrogerToken();

      await addItemsToCart(items, krogerAuthToken);
      setOrderPlaced(true);
      openKrogerCart();
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to add items to cart');
      setOrderPlaced(false);
    } finally {
      setIsLoading(false);
    }
  }, [hasKrogerToken, recipe, modality]);

  return (
    <button 
      className={styles.button} 
      onClick={addToCart}
      disabled={isLoading}
    >
      <img src="/kroger_white.png" alt="Kroger Logo" className={styles.krogerLogo} />
      {isLoading ? 'Adding...' : orderPlaced ? 'Added to Cart ' : 'Add to Cart '}
      {orderPlaced && <span className={styles.checkmark}>&#10004;</span>}
      {error && <div className={styles.error}>{error}</div>}
    </button>
  );
};

export default AddToCartButton;