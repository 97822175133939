import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { User } from 'firebase/auth';
import { auth } from '../firebase.config.ts';
import { refreshKrogerToken } from '../api/api.ts';

const LOCAL_STORAGE_AUTH_KEY = 'firebase_auth_token';
const LOCAL_STORAGE_KROGER_KEY = 'kroger_auth_token';
const LOCAL_STORAGE_KROGER_REFRESH_KEY = 'kroger_refresh_token';

interface AuthState {
  // Firebase auth
  firebaseToken: string | null;
  // Kroger auth
  krogerToken: string | null;
  krogerRefreshToken: string | null;
  lastKrogerTokenRefresh: number | null;
  
  // Firebase actions
  setFirebaseToken: (token: string) => void;
  
  // Kroger actions
  setKrogerTokens: (accessToken: string, refreshToken: string) => void;
  clearKrogerTokens: () => void;
  checkKrogerTokenExpiry: () => Promise<void>;
  getValidKrogerToken: () => Promise<string>;

  user: User | null;
  loading: boolean;
  
  setUser: (user: User | null) => void;
  setLoading: (loading: boolean) => void;
  initializeAuthListener: () => () => void;

  lastFirebaseTokenRefresh: number | null;
  checkFirebaseTokenExpiry: () => Promise<void>;
  getValidFirebaseToken: () => Promise<string>;
}

export const useAuthStore = create<AuthState>()(
  devtools(
    (set, get) => ({
      firebaseToken: typeof window !== 'undefined' ? localStorage.getItem(LOCAL_STORAGE_AUTH_KEY) : null,
      krogerToken: typeof window !== 'undefined' ? localStorage.getItem(LOCAL_STORAGE_KROGER_KEY) : null,
      krogerRefreshToken: typeof window !== 'undefined' ? localStorage.getItem(LOCAL_STORAGE_KROGER_REFRESH_KEY) : null,
      lastKrogerTokenRefresh: null,
      user: null,
      loading: true,
      
      setFirebaseToken: (token: string) => {
        if (typeof window !== 'undefined') {
          localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, token);
          localStorage.setItem('last_token_refresh', Date.now().toString());
        }
        set({ 
          firebaseToken: token,
          lastFirebaseTokenRefresh: Date.now()
        });
      },

      setKrogerTokens: (accessToken: string, refreshToken: string) => {
        if (typeof window !== 'undefined') {
          localStorage.setItem(LOCAL_STORAGE_KROGER_KEY, accessToken);
          localStorage.setItem(LOCAL_STORAGE_KROGER_REFRESH_KEY, refreshToken);
        }
        set({ 
          krogerToken: accessToken,
          krogerRefreshToken: refreshToken,
          lastKrogerTokenRefresh: Date.now()
        });
      },

      clearKrogerTokens: () => {
        if (typeof window !== 'undefined') {
          localStorage.removeItem(LOCAL_STORAGE_KROGER_KEY);
          localStorage.removeItem(LOCAL_STORAGE_KROGER_REFRESH_KEY);
        }
        set({ 
          krogerToken: null,
          krogerRefreshToken: null,
          lastKrogerTokenRefresh: null
        });
      },

      setUser: (user: User | null) => set({ user }),
      setLoading: (loading: boolean) => set({ loading }),
      
      initializeAuthListener: () => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          set({ user, loading: false });
        });
        return unsubscribe;
      },

      lastFirebaseTokenRefresh: null,

      checkKrogerTokenExpiry: async () => {
        const lastRefresh = get().lastKrogerTokenRefresh;
        const now = Date.now();
        
        // If no last refresh or it's been > 50 minutes
        if (!lastRefresh || (now - lastRefresh > 50 * 60 * 1000)) {
          const refreshToken = get().krogerRefreshToken;
          if (refreshToken) {
            try {
              // You'll need to implement this function to call Kroger's token refresh endpoint
              const response = await refreshKrogerToken(refreshToken);
              get().setKrogerTokens(response.accessToken, response.refreshToken);
            } catch (error) {
              console.error('Kroger token refresh failed:', error);
              get().clearKrogerTokens();
            }
          }
        }
      },

      getValidKrogerToken: async () => {
        await get().checkKrogerTokenExpiry();
        return get().krogerToken!;
      },

      checkFirebaseTokenExpiry: async () => {
        const lastRefresh = get().lastFirebaseTokenRefresh;
        const now = Date.now();
        
        // If no last refresh or it's been > 50 minutes
        if (!lastRefresh || (now - lastRefresh > 50 * 60 * 1000)) {
          const user = get().user;
          if (user) {
            try {
              const token = await user.getIdToken(true);
              get().setFirebaseToken(token);
              set({ lastFirebaseTokenRefresh: now });
            } catch (error) {
              console.error('Token refresh failed:', error);
            }
          }
        }
      },

      getValidFirebaseToken: async () => {
        await get().checkFirebaseTokenExpiry();
        return get().firebaseToken!;
      }
    })
  )
);
