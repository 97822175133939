import styled from 'styled-components';
import { keyframes } from 'styled-components';

export const pulse = keyframes`
  0% { transform: scale(0.95); opacity: 0.8; }
  50% { transform: scale(1.05); opacity: 1; }
  100% { transform: scale(0.95); opacity: 0.8; }
`;

export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-720deg); }
`;

export const slide = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
`;

export const roll = keyframes`
  0% { 
    transform: translateX(100%) rotate(0deg);
  }
  100% { 
    transform: translateX(-100%) rotate(-720deg);
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;
`;

export const RollingLogo = styled.img`
  width: 48px;
  height: 48px;
  transform-origin: center center;
  animation: 
    ${roll} 2s linear infinite,
    ${pulse} 1s infinite ease-in-out;
  will-change: transform;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
`;

export const ModalContainer = styled.div`
  position: relative;
  background: white;
  width: 90%;
  max-width: 800px;
  height: 90vh;
  border-radius: 4px;
  padding: 40px 20px 20px; // Add top padding to account for close button
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

export const RefreshButton = styled.button`
  padding: 1rem;
  background-color: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 500;
  color: #495057;
  transition: all 0.2s ease;
  min-width: 120px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  
  &:hover {
    background-color: #f8f9fa;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
`;