import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../config';
import { useAuthStore } from '../state/authStore.ts';

let requestMade = false;

const OAuthCallback = () => {
  const navigate = useNavigate();
  const { setKrogerTokens } = useAuthStore();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = decodeURIComponent(urlParams.get('state')!);

    const getAccessToken = async () => {
      if (requestMade) return;
      requestMade = true;

      const response = await axios.get(`${API_URL}/get_access_token`, { 
          params: { code } 
      });
      setKrogerTokens(response.data.access_token, response.data.refresh_token);
      navigate(state || '/');
    };

    if (code) {
      getAccessToken();
    }
  }, [navigate, setKrogerTokens]);

  return null;
};

export default OAuthCallback;