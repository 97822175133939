import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonContainer } from './ButtonGrid.tsx';
import { Recipe } from './types';
import API_URL from '../config.js';

interface RecipeTitleScrollProps {
  selectedTheme: string;
  onRecipeClick: (recipeName: string) => void;
  onBack: () => void;
  extractEmojiAndText: (str: string) => { emoji: string; text: string };
}

const RecipeTitleScroll: React.FC<RecipeTitleScrollProps> = ({
  selectedTheme,
  onRecipeClick,
  onBack,
  extractEmojiAndText,
}) => {
  const navigate = useNavigate();
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchRecipes = async (isInitial = true) => {
    if (isInitial) {
      setPage(1);
      setHasMore(true);
    }
    setIsLoading(true);

    const currentPage = isInitial ? 1 : page;
    console.log(`Loading recipes page ${currentPage}`);

    try {
      const response = await fetch(`${API_URL}/copilot/recipes-by-theme/${selectedTheme}?page=${currentPage}`);
      const titles: string[] = await response.json();
      
      if (!titles || titles.length === 0) {
        setHasMore(false);
        return;
      }
      
      const recipeObjects: Recipe[] = titles.map(title => ({
        id: crypto.randomUUID(),
        name: title
      }));
      
      setRecipes(prev => isInitial ? recipeObjects : [...prev, ...recipeObjects]);
      if (!isInitial) {
        setPage(prev => prev + 1);
      }
    } catch (error) {
      console.error('Error fetching recipes:', error);
      setRecipes([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRecipes(true);
  }, [selectedTheme]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data === 'REACHED_BOTTOM' && hasMore && !isLoading) {
        handleLoadMore();
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [page, hasMore, isLoading]);

  const recipeButtons = recipes.map(recipe => {
    const {emoji, text} = extractEmojiAndText(recipe.name)
    return {
      text: text,
      emoji: emoji,
      onClick: () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        navigate(`/search/${text.replace(/ /g, '_')}`);
        onRecipeClick(recipe.name);
      },
      customWidth: '120px',
      customHeight: 'auto'
    };
  });

  const handleRefresh = () => {
    setPage(1);
    setHasMore(true);
    setRecipes([]);
    fetchRecipes(true);
  };

  const handleLoadMore = async () => {
    if (!hasMore || isLoading) return;
    await fetchRecipes(false);
  };

  return (
    <div style={{ 
      width: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      gap: '0',
      paddingBottom: '160px',
      margin: '0.5rem 0 0 0',
      alignItems: 'center'
    }}>
      <div 
        onClick={() => {
          onBack();
        }}
        style={{
          position: 'sticky',
          top: '64px',
          left: 0,
          right: 0,
          zIndex: 1000,
          backgroundColor: '#f8f9fa',
          padding: '0.75rem 0',
          borderBottom: '1px solid #eee',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '0.5rem',
          cursor: 'pointer',
          userSelect: 'none',
          width: '100%'
        }}
      >
        <span>↩️</span>
        <span>Return to Themes</span>
      </div>
      <ButtonContainer
        buttons={recipeButtons}
        isScrollable={false}
        isLoading={isLoading}
        skeletonCount={20}
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          padding: 0,
          gap: '0.5rem',
          margin: 0
        }}
      />
    </div>
  );
};

export default RecipeTitleScroll;