import React, { useEffect } from 'react';
import axios from 'axios';
import API_URL from '../config';
import styles from './OAuthButton.module.css';
import { useAuthStore } from '../state/authStore.ts';

const OAuthButton = () => {
  const { krogerToken, krogerRefreshToken, setKrogerTokens } = useAuthStore();

  const getAuthCodeUrl = async (currentPath) => {
    const stateValue = encodeURIComponent(currentPath);
    const response = await axios.get(`${API_URL}/auth_code_url?state=${stateValue}`);
    window.location.href = response.data.url;
  };

  const getAccessToken = async (code) => {
    const response = await axios.post(`${API_URL}/get_access_token`, { code });
    setKrogerTokens(response.data.access_token, response.data.refresh_token);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const originalPath = decodeURIComponent(urlParams.get('state')!);
    if (code) {
      getAccessToken(code)
        .then(() => {
          if (originalPath) {
            window.location.href = originalPath;
          }
        });
    }
  }, []);

  return (
    <button
      className={styles.button}
      onClick={() => getAuthCodeUrl(window.location.pathname)}
    >
        <img src="/kroger_white.png" alt="Kroger Logo" className={styles.krogerLogo} />
        {!krogerToken ? 'Sign In to Order' : 'Signed In'}
    </button>
);
};

export default OAuthButton;