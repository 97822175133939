import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import RecipeDescription from './components/RecipeDescription';
import RecipeIngredients from './components/RecipeIngredients.tsx';
import { FaSyncAlt } from 'react-icons/fa';
import './RecipeView.css';
import { useRecipeStore } from './state/recipeStore.ts';

type RouteParams = {
    recipeId: string;
}

const RecipeView: React.FC = () => {
    const params = useParams<RouteParams>();
    const recipeId = params.recipeId as string;
    const { 
        recipes,
        reprocessRecipe,
        loadingRecipe: loading,
        recipeError: error,
        fetchRecipe
    } = useRecipeStore();
    const recipe = recipes[parseInt(recipeId)];
    const [statusIndicator, setStatusIndicator] = useState<string>('');

    const handleRecompute = useCallback(() => {
        if (recipe && recipe.source_url) {
            reprocessRecipe(recipeId, recipe.source_url);
        }
    }, [recipe, recipeId, reprocessRecipe]);

    useEffect(() => {
        fetchRecipe(recipeId);
    }, [recipeId, fetchRecipe]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!recipe || loading) {
        return <div>Loading...</div>;
    }

    return (
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
            <div className="recipe-container">
                <div className="recipe-section">
                    <RecipeDescription recipe={recipe} />
                </div>
                <div className="recipe-section ingredients">
                    <RecipeIngredients recipe={recipe} />
                </div>
            </div>
            <div>
                <FaSyncAlt 
                    className={`${loading ? 'icon-spin' : ''}`} 
                    style={{ fontSize: '24px', cursor: 'pointer', color: '#ccc'}} 
                    onClick={handleRecompute} 
                />
            </div>
            {loading && (
                <div className="loading-container">
                    <div className="spinner"></div>
                    <p>Recomputing...{statusIndicator}</p>
                </div>
            )}
        </div>
    );
};

export default RecipeView;