import React, { useState } from "react";
import { Link } from "react-router-dom"; // Add this import
import { MealPrepSelection } from "../api/types.ts";
import { useMealPlanStore } from "../state/mealPlanStore.ts";
import { useRecipeStore } from "../state/recipeStore.ts";

const RecipeCard = ({
    children,
    onDelete
}: {
    children: React.ReactNode,
    onDelete: () => void
}) => {
    return (
        <div className="recipe-container" style={{
            position: 'relative',
            padding: '12px',
            margin: '4px',
            border: '1px solid #e9ecef',
            borderRadius: '8px',
            backgroundColor: 'white',
            width: '200px',
            flexShrink: 0,
            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
            transition: 'all 0.2s ease',
            cursor: 'pointer',
            ':hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
            }
        }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
            }}>
                {children}
            </div>
            <button
                onClick={onDelete}
                style={{
                    position: 'absolute',
                    top: '8px',
                    right: '8px',
                    padding: '4px',
                    backgroundColor: '#dc3545',
                    color: 'white',
                    border: 'none',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    fontSize: '1rem',
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transition: 'all 0.2s ease',
                    ':hover': {
                        backgroundColor: '#c82333',
                        transform: 'scale(1.1)'
                    }
                }}
            >
                ×
            </button>
        </div>
    );
};

export const RecipeSelectionBanner: React.FC = () => {
    const { isLoading: mealPlanLoading, prepSelections: mealPlan } = useMealPlanStore();
    const { recipes } = useRecipeStore();
    const [deletingIds, setDeletingIds] = useState<number[]>([]);
    const [error, setError] = useState<string | null>(null);
    
    const handleDelete = (selectionId: number) => {
        const mealPlanStore = useMealPlanStore.getState();
        try {
            setDeletingIds(prev => [...prev, selectionId]);
            mealPlanStore.removeAllSelectionsForRecipe(selectionId);
        } catch (err) {
            console.error('Failed to delete selection:', err);
            setError(err instanceof Error ? err.message : 'Failed to delete selection');
            mealPlanStore.fetchMealPlan();
        } finally {
            setDeletingIds(prev => prev.filter(id => id !== selectionId));
        }
    };

    const allRecipesLoaded = mealPlan.every(plannedMeal => recipes[plannedMeal.recipe_id]);

    return (
        <div style={{ 
            backgroundColor: '#f8f9fa',
            borderTop: '1px solid #dee2e6',
            padding: '16px',
            width: '100%',
            boxSizing: 'border-box',
            boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 1000,
            backgroundColor: 'white'
        }}>
            <div style={{ 
                fontSize: '1.25rem',
                fontWeight: 'bold',
                marginBottom: '16px',
                paddingLeft: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <span>Meal Plan</span>
                <Link
                    to="/calendar"
                    style={{
                        fontSize: '0.875rem',
                        color: '#007bff',
                        textDecoration: 'none',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        transition: 'background-color 0.2s ease',
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                >
                    Meal Plan Details →
                </Link>
            </div>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                overflowX: 'auto',
                gap: '1rem',
                padding: '8px',
                WebkitOverflowScrolling: 'touch',
                msOverflowStyle: '-ms-autohiding-scrollbar'
            }}>
                {mealPlanLoading || !allRecipesLoaded ? (
                    <span style={{ color: "#666", fontStyle: "italic", fontSize: "1rem" }}>Loading...</span>
                ) : mealPlan.length === 0 ? (
                    <span style={{ color: "#666", fontStyle: "italic", fontSize: "1rem" }}>No meals selected</span>
                ) : (
                    <div style={{ display: "flex", gap: "1rem" }}>
                        {mealPlan.map((plannedMeal: MealPrepSelection) => {
                            const recipe = recipes[plannedMeal.recipe_id];
                            if (!recipe) return null;

                            return (
                                <RecipeCard
                                    key={plannedMeal.recipe_id}
                                    onDelete={() => handleDelete(plannedMeal.recipe_id)}
                                >
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "1rem"
                                    }}>
                                        <img
                                            src={recipe.picture_link}
                                            alt={recipe.title}
                                            style={{
                                                width: "48px",
                                                height: "48px",
                                                objectFit: "cover",
                                                borderRadius: "6px"
                                            }}
                                        />
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}>
                                            <span style={{
                                                fontSize: "1rem",
                                                width: "120px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                fontWeight: "500",
                                                color: "#495057"
                                            }}>
                                                {recipe.title}
                                            </span>
                                            <span style={{
                                                fontSize: "0.875rem",
                                                color: "#666"
                                            }}>
                                                {plannedMeal.servings} servings
                                            </span>
                                        </div>
                                    </div>
                                </RecipeCard>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};