import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import Header from './Header.tsx';
import RecipeView from './RecipeView.tsx';
import MealPlan from './MealPlan.tsx';
import OAuthCallback from './components/OAuthCallback.ts';
import { AuthProvider } from './AuthContext.tsx';
import { useAuthStore } from './state/authStore.ts';
import { useMealPlanStore } from './state/mealPlanStore.ts';
import {Login} from './Login.tsx';
import MealPlanner from './Home.tsx';
import { CalendarPlanner } from './CalendarPlanner.tsx';
import CopilotBanner from './RecipePlanner/CopilotSearch.tsx';
import Home from './Home.tsx';

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuthStore();
  
  if (loading) return <div>Loading...</div>;
  if (!user) return <Navigate to="/login" />;
  
  return children;
};

const App = () => {
  const initializeAuthListener = useAuthStore(state => state.initializeAuthListener);
  const fetchMealPlan = useMealPlanStore(state => state.fetchMealPlan);
  
  useEffect(() => {
    const unsubscribe = initializeAuthListener();
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetchMealPlan();
  }, []);

  return (
    <Router>
      <div style={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        height: '100vh',
      }}>
        <AuthProvider>
          <Header />
          <Routes>
            <Route path="/" element={<CopilotBanner />} />
            <Route path="/calendar" element={<CalendarPlanner />} />
            <Route path="/recipe-view/:recipeId" element={<RecipeView />} />
            <Route path="/login" element={<Login />} />
            <Route 
              path="/meal-plan" 
              element={
                <PrivateRoute>
                  <MealPlan />
                </PrivateRoute>
              } 
            />
            <Route path="/inspiration" element={<CopilotBanner />} />
            <Route path="/inspiration/:theme" element={<CopilotBanner />} />
            <Route path="/search/:recipeName" element={<CopilotBanner />} />
            <Route path="/recipes" element={<Home />} />
            <Route path="/kroger_auth" element={<OAuthCallback />} />
          </Routes>
        </AuthProvider>
      </div>
    </Router>
  );
};

export default App;