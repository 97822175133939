import React from 'react';
import RecipePriceDisplay from './RecipePriceDisplay.tsx';
import { Recipe } from '../state/recipeStore.ts';

interface RecipeCheckoutProps {
  recipe: Recipe;
}

const RecipeCheckout: React.FC<RecipeCheckoutProps> = ({ recipe }) => {
  return (
    <div>
      <RecipePriceDisplay recipeId={recipe.id} />
    </div>
  );
};
export default RecipeCheckout;