import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { auth } from './firebase.config.ts';
import { signOut } from 'firebase/auth';
import './Header.css';
import { useAuthStore } from './state/authStore.ts';
import API_URL from './config.js';

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { user } = useAuthStore();
  const { setFirebaseToken, clearKrogerTokens, } = useAuthStore();
  const { krogerToken } = useAuthStore();

  const handleProfileClick = () => {
    setShowMenu(!showMenu);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setFirebaseToken("");
      clearKrogerTokens();
      setShowMenu(false);
      window.location.reload();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleKrogerSignOut = () => {
    clearKrogerTokens();
    setShowMenu(false);
  };

  const getAuthCodeUrl = async () => {
    const stateValue = encodeURIComponent(window.location.pathname);
    const response = await fetch(`${API_URL}/auth_code_url?state=${stateValue}`);
    const data = await response.json();
    window.location.href = data.url;
  };

  return (
    <header className="header">
      <Link to="/" className="logo-container" style={{ textDecoration: 'none' }}>
        <img src="/logo512.png" alt="Kitchen Copilot Logo" className="logo" />
        <h1 className="app-name">Kitchen Copilot</h1>
      </Link>
      <nav className="navigation">
        {user ? (
          <div className="user-section">
            <div className="profile-container" style={{ position: 'relative' }}>
              {user.photoURL && (
                <img 
                  src={user.photoURL}
                  alt="Profile"
                  className="profile-picture"
                  referrerPolicy="no-referrer"
                  onClick={handleProfileClick}
                  style={{ cursor: 'pointer' }}
                />
              )}
              {showMenu && (
                <div className="profile-menu" style={{ zIndex: 2000 }}>
                  <div className="menu-user-info">
                    <img 
                      src={user.photoURL}
                      alt="Profile"
                      className="menu-profile-picture"
                      referrerPolicy="no-referrer"
                    />
                    <div className="menu-user-details">
                      <div className="menu-user-name">{user.displayName}</div>
                      <div className="menu-user-email">{user.email}</div>
                    </div>
                  </div>
                  {krogerToken ? (
                    <div onClick={handleKrogerSignOut} className="menu-item">
                      Disconnect Kroger
                    </div>
                  ) : (
                    <div onClick={getAuthCodeUrl} className="menu-item">
                      Connect Kroger
                    </div>
                  )}
                  <div onClick={handleSignOut} className="menu-item">
                    Sign Out
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <Link to="/login" className="header-link">Sign In</Link>
        )}
      </nav>
    </header>
  );
};

export default Header;
