import React, { useState } from "react";
import { MealPrepSelection } from "../api/types.ts";
import { useDraggable } from "@dnd-kit/core";
import { useMealPlanStore } from "../state/mealPlanStore.ts";
import { useRecipeStore } from "../state/recipeStore.ts";

const DraggableRecipe = ({
    id,
    children,
    mealData,
    onDelete
}: {
    id: string,
    children: React.ReactNode,
    mealData: MealPrepSelection,
    onDelete: () => void
}) => {
    const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
        id: id,
        data: { mealData }
    });

    return (
        <div className="recipe-container" style={{
            position: 'relative',
            padding: '4px',
            margin: '0 8px 8px 0',
            border: '1px solid #ccc',
            borderRadius: '4px',
            backgroundColor: 'white',
            transition: 'box-shadow 0.2s ease',
            boxShadow: isDragging ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
            maxWidth: '100%',
            boxSizing: 'border-box',
            zIndex: isDragging ? 1000 : 1,
            opacity: isDragging ? 0.5 : 1,
        }}>
            <div 
                ref={setNodeRef}
                {...listeners} 
                {...attributes}
                style={{
                    cursor: 'grab',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {children}
            </div>
            <button
                onClick={onDelete}
                style={{
                    position: 'absolute',
                    top: '2px',
                    right: '2px',
                    padding: '2px',
                    backgroundColor: '#dc3545',
                    color: 'white',
                    border: 'none',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    fontSize: '0.7rem',
                    width: '16px',
                    height: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                ×
            </button>
        </div>
    );
};

export const PreppedRecipeList: React.FC = () => {
    const { isLoading: mealPlanLoading, prepSelections: mealPlan } = useMealPlanStore();
    const { recipes } = useRecipeStore();
    const [deletingIds, setDeletingIds] = useState<number[]>([]);
    const [error, setError] = useState<string | null>(null);
    
    const handleDelete = (selectionId: number) => {
        const mealPlanStore = useMealPlanStore.getState();
        try {
            setDeletingIds(prev => [...prev, selectionId]);
            mealPlanStore.removeAllSelectionsForRecipe(selectionId);
        } catch (err) {
            console.error('Failed to delete selection:', err);
            setError(err instanceof Error ? err.message : 'Failed to delete selection');
            mealPlanStore.fetchMealPlan();
        } finally {
            setDeletingIds(prev => prev.filter(id => id !== selectionId));
        }
    };

    const allRecipesLoaded = mealPlan.every(plannedMeal => recipes[plannedMeal.recipe_id]);

    return (
        <div style={{ 
            backgroundColor: '#f8f9fa',
            borderTop: '1px solid #dee2e6',
            borderBottom: '1px solid #dee2e6',
            padding: '12px',
            width: '100%',
            boxSizing: 'border-box', // Added
            overflowX: 'hidden' // Added
        }}>
            <div style={{ 
                fontSize: '1rem',
                fontWeight: 'bold',
                marginBottom: '12px',
                paddingLeft: '4px'
            }}>
                Meal Plan
            </div>

            <div style={{
                display: 'flex',
                alignItems: 'flex-start', // Changed from center
                gap: '0.5rem',
                padding: '4px',
                flexWrap: 'wrap', // Added
                width: '100%', // Added
                boxSizing: 'border-box', // Added
            }}>
                {mealPlanLoading || !allRecipesLoaded ? (
                    <span style={{ color: "#666", fontStyle: "italic" }}>Loading...</span>
                ) : mealPlan.length === 0 ? (
                    <span style={{ color: "#666", fontStyle: "italic" }}>No meals selected</span>
                ) : (
                    <div style={{ 
                        display: "flex", 
                        gap: "0.5rem",
                        flexWrap: 'wrap', // Added
                        width: '100%', // Added
                        boxSizing: 'border-box', // Added
                    }}>
                        {mealPlan.map((plannedMeal: MealPrepSelection) => {
                            const recipe = recipes[plannedMeal.recipe_id];
                            if (!recipe) return null;

                            return (
                                <DraggableRecipe
                                    key={plannedMeal.recipe_id}
                                    id={`recipe-${plannedMeal.recipe_id}`}
                                    mealData={plannedMeal}
                                    onDelete={() => handleDelete(plannedMeal.recipe_id)}
                                >
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "0.5rem"
                                    }}>
                                        <img
                                            src={recipe.picture_link}
                                            alt={recipe.title}
                                            style={{
                                                width: "32px",
                                                height: "32px",
                                                objectFit: "cover",
                                                borderRadius: "4px"
                                            }}
                                        />
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}>
                                            <span style={{
                                                fontSize: "0.8rem",
                                                maxWidth: "80px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap"
                                            }}>
                                                {recipe.title}
                                            </span>
                                            <span style={{
                                                fontSize: "0.7rem",
                                                color: "#666"
                                            }}>
                                                {plannedMeal.servings} servings
                                            </span>
                                        </div>
                                    </div>
                                </DraggableRecipe>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};