import React, { useEffect } from 'react';
import styles from './RecipePriceDisplay.module.css';
import AddToCartButton from './AddToCartButton.tsx';
import OAuthButton from './OAuthButton.tsx';
import AddToMealPlanButton from './AddToMealPlanButton.tsx';
import { getProductPriceInfo, getProductQuantity } from '../utils/PriceCalculation.ts';
import { RecipeIngredient, useRecipeStore } from '../state/recipeStore.ts';
import { useSelectionStore } from '../state/selectionStore.ts';
import { useAuthStore } from '../state/authStore.ts';

interface PriceInfo {
    priceOfQuantityUsed: number;
    priceOfQuantityRemaining: number;
}

interface RecipePriceDisplayProps {
    recipeId: number;
}

const RecipePriceDisplay: React.FC<RecipePriceDisplayProps> = ({ recipeId }) => {
    const krogerAuthToken = useAuthStore(state => state.krogerToken);
    const { recipes, fetchRecipe, loadingStates } = useRecipeStore();
    const { 
        getSelectedIngredientOption, 
        getSelectedProduct, 
        isSelectionsLoading,
        getSelectedForPurchase,
        getSelectedQuantity 
    } = useSelectionStore();

    const recipe = recipes[recipeId];
    const selectionsLoading = isSelectionsLoading(recipeId);
    const recipeLoading = !recipe;
    
    // Check if any ingredients are loading
    const ingredientsLoading = recipe?.recipeIngredients ? 
        Object.values(recipe.recipeIngredients).some(ingredient => 
            loadingStates[recipeId]?.[ingredient.id]?.ingredients ||
            Object.values(loadingStates[recipeId]?.[ingredient.id]?.products || {}).some(loading => loading)
        ) : false;

    const isLoading = selectionsLoading || recipeLoading || ingredientsLoading;

    useEffect(() => {
        if (!recipe) {
            fetchRecipe(recipeId);
        }
    }, [recipeId, recipe, fetchRecipe]);

    const calculateIngredientPrice = (recipeIngredient: RecipeIngredient): number => {
        const ingredientOption = getSelectedIngredientOption(recipeId, recipeIngredient.id);
        if (!ingredientOption) return 0;

        const product = getSelectedProduct(recipeId, recipeIngredient.id);
        if (!product?.price) return 0;

        const selectedForPurchase = getSelectedForPurchase(recipeId, recipeIngredient.id);
        if (!selectedForPurchase) return 0;

        const selectedQuantity = getSelectedQuantity(recipeId, recipeIngredient.id);
        console.log('selectedQuantity', selectedQuantity)
        console.log('product.price', product.price)
        console.log('product.price * selectedQuantity', product.price * selectedQuantity)
        return product.price * selectedQuantity;
    };

    const calculateIngredientPriceInfo = (recipeIngredient: RecipeIngredient): PriceInfo => {
        const ingredientOption = getSelectedIngredientOption(recipeId, recipeIngredient.id);
        if (!ingredientOption) return { priceOfQuantityUsed: 0, priceOfQuantityRemaining: 0 };

        const product = getSelectedProduct(recipeId, recipeIngredient.id);
        if (!product) return { priceOfQuantityUsed: 0, priceOfQuantityRemaining: 0 };

        const selectedForPurchase = getSelectedForPurchase(recipeId, recipeIngredient.id);
        if (!selectedForPurchase) return { priceOfQuantityUsed: 0, priceOfQuantityRemaining: 0 };

        const priceInfo = getProductPriceInfo(product, recipeIngredient);
        if (!priceInfo) return { priceOfQuantityUsed: 0, priceOfQuantityRemaining: 0 };

        const selectedQuantity = getSelectedQuantity(recipeId, recipeIngredient.id);
        const quantityNeeded = getProductQuantity(product, recipeIngredient) || 1;
        const multiplier = selectedQuantity / quantityNeeded;

        return {
            priceOfQuantityUsed: priceInfo.priceOfQuantityUsed * multiplier,
            priceOfQuantityRemaining: priceInfo.priceOfQuantityRemaining * multiplier
        };
    };

    const price: number = recipe?.recipeIngredients ? 
        Object.values(recipe.recipeIngredients).reduce((total, recipeIngredient) => {
            return total + calculateIngredientPrice(recipeIngredient);
        }, 0) : 0;

    const priceInfo: PriceInfo = recipe?.recipeIngredients ?
        Object.values(recipe.recipeIngredients).reduce((total, recipeIngredient) => {
            const ingredientPriceInfo = calculateIngredientPriceInfo(recipeIngredient);
            return {
                priceOfQuantityUsed: total.priceOfQuantityUsed + ingredientPriceInfo.priceOfQuantityUsed,
                priceOfQuantityRemaining: total.priceOfQuantityRemaining + ingredientPriceInfo.priceOfQuantityRemaining
            };
        }, { priceOfQuantityUsed: 0, priceOfQuantityRemaining: 0 })
        : { priceOfQuantityUsed: 0, priceOfQuantityRemaining: 0 };

    if (isLoading) {
        return (
            <div className={styles.pricePill}>
                <div className={styles.price}>
                    <div className={styles.skeletonPrice} />
                </div>
                <div className={styles.recipePriceDetails}>
                    <div className={styles.skeletonDetails} />
                </div>
            </div>
        );
    }

    if (!recipe) {
        return <div>Loading...</div>;
    }

    return (
        <div className={styles.pricePill}>
            <div className={styles.price}>
                Recipe Cost: ${(price - priceInfo.priceOfQuantityRemaining).toFixed(2)}
            </div>
            <div className={styles.recipePriceDetails}>
                <div>Grocery Cost: ${price.toFixed(2)} | Leftover: ${priceInfo.priceOfQuantityRemaining.toFixed(2)}</div>
            </div>
            {recipe && (
                <div className={styles.buttonContainer}>
                    {krogerAuthToken ? (
                        <AddToCartButton recipe={recipe} modality="PICKUP" />
                    ) : (
                        <OAuthButton />
                    )}
                    <AddToMealPlanButton recipe={recipe} />
                </div>
            )}
        </div>
    );
};

export default RecipePriceDisplay;