import React, { useState, useEffect } from 'react';
import API_URL from '../config.js';
import { useRecipeStore } from '../state/recipeStore.ts';
import { LoadingContainer, RollingLogo, RefreshButton } from './styles.ts';
import WebRecipeSelector from './WebRecipeSelector.tsx';
import ThemeScroll from './ThemeScroll.tsx';
import RecipeTitleScroll from './RecipeTitleScroll.tsx';
import { RecipeSelectionBanner } from './RecipeSelectionBanner.tsx';
import { useParams, useNavigate } from 'react-router-dom';
import { NavToggleHeader } from './NavToggleHeader.tsx';

interface RecipeOption {
  title: string;
  site_url: string;
  main_site: string;
  rating: number;
  description: string;
}

export const LoadingAnimation: React.FC = () => (
    <LoadingContainer>
      <RollingLogo src="/logo512.png" alt="Loading..." />
    </LoadingContainer>
);

const FOOTER_HEIGHT = '160px';

const CopilotBanner: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isReviewMode, setIsReviewMode] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState('');
  const [isRecipeSelectMode, setIsRecipeSelectMode] = useState(false);
  const [recipeOptions, setRecipeOptions] = useState<RecipeOption[]>([]);
  const [selectedRecipe, setSelectedRecipe] = useState('');
  const { theme, recipeName } = useParams<{ theme?: string, recipeName?: string }>();
  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get('p');
  const startIndex = pageParam ? parseInt(pageParam, 10) : 0;
  const [mode, setMode] = useState<'search' | 'copilot'>('copilot');

  useEffect(() => {
    if (theme) {
      setSelectedTheme(decodeURIComponent(theme).replace(/_/g, ' '));
      setIsReviewMode(true);
    } else {
      setSelectedTheme('');
      setIsReviewMode(false);
    }
    
    if (recipeName) {
      setSelectedRecipe(decodeURIComponent(recipeName).replace(/_/g, ' '));
      setIsRecipeSelectMode(true);
      fetchRecipeOptions(decodeURIComponent(recipeName).replace(/_/g, ' '));
    } else {
      setSelectedRecipe('');
      setIsRecipeSelectMode(false);
    }
  }, [theme, recipeName]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const documentHeight = document.documentElement.scrollHeight;
      
      if ((documentHeight <= window.innerHeight || scrollPosition >= documentHeight - 100) 
          && (mode === 'copilot' || mode === 'search')) {
        window.postMessage('REACHED_BOTTOM', '*');
        // Handle scroll in 1 second
        setTimeout(() => {
          handleScroll();
        }, 1000);
      }
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [mode]);

  const handleThemeClick = (suggestion: string) => {
    const { text } = extractEmojiAndText(suggestion);
    setSelectedTheme(text);
    setIsReviewMode(true);
    navigate(`/inspiration/${text.replace(/ /g, '_')}`);
  };

  const fetchRecipeOptions = async (recipeTitle: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/copilot/recipe-options/${encodeURIComponent(recipeTitle)}`);
      const options: RecipeOption[] = await response.json();
      setRecipeOptions(options);
    } catch (error) {
      console.error('Error fetching recipe options:', error);
      setRecipeOptions([]);
    } finally {
      setIsLoading(false);
    }
  };

  const extractEmojiAndText = (str: string) => {
    // Updated regex that handles compound emojis and modifiers
    const emojiRegex = /^[\p{Emoji}\u{FE0F}\u{1F3FB}-\u{1F3FF}]+([\u{200D}\p{Emoji}\u{FE0F}\u{1F3FB}-\u{1F3FF}]*[\p{Emoji}\u{FE0F}])?/u;
    const match = str.match(emojiRegex);
    
    if (match) {
      const emoji = match[0];
      const text = str.slice(emoji.length).trim();
      return { emoji, text };
    }
    
    return { emoji: '🍽️', text: str.trim() };
  };

  const handleCancel = () => {
    navigate('/inspiration');
  };

  const renderContent = () => {
    if (isRecipeSelectMode) {
      return (
        <WebRecipeSelector
          isLoading={isLoading}
          recipeOptions={recipeOptions}
          onBack={() => {
            setIsRecipeSelectMode(false);
            setRecipeOptions([]);
          }}
        />
      );
    }

    if (isReviewMode) {
      return (
        <RecipeTitleScroll
          selectedTheme={selectedTheme}
          onRecipeClick={(recipeName) => {
            setSelectedRecipe(recipeName);
            setIsRecipeSelectMode(true);
            fetchRecipeOptions(recipeName);
          }}
          onBack={handleCancel}
          extractEmojiAndText={extractEmojiAndText}
        />
      );
    }

    return (
      <ThemeScroll
        onThemeClick={handleThemeClick}
        extractEmojiAndText={extractEmojiAndText}
        startIndex={startIndex}
      />
    );
  };

  return (
    <div>
      <NavToggleHeader mode={mode}/>

      <div style={{ 
        display: 'flex', 
        flexDirection: 'column',
        paddingBottom: FOOTER_HEIGHT,
        marginBottom: 0,
        marginTop: 0,
        paddingTop: 0
      }}>
        {renderContent()}
      </div>

      <RecipeSelectionBanner />
    </div>
  );
};

export default CopilotBanner;