import React, { useState, useEffect } from 'react';
import { useAuthStore } from '../state/authStore.ts';
import styles from './AddToMealPlanButton.module.css';
import { Recipe } from '../state/recipeStore.ts';
import { useSelectionStore } from '../state/selectionStore.ts';
import { useMealPlanStore } from '../state/mealPlanStore.ts';

interface AddToMealPlanButtonProps {
  recipe: Recipe;
}

const AddToMealPlanButton: React.FC<AddToMealPlanButtonProps> = ({ recipe }) => {
  const { prepSelections, addToMealPrepPlan, isLoading, error } = useMealPlanStore();
  const isAlreadyAdded = prepSelections.some(s => s.recipe_id === recipe.id);
  const [added, setAdded] = useState(isAlreadyAdded);

  useEffect(() => {
    setAdded(isAlreadyAdded);
  }, [isAlreadyAdded]);

  const handleAddToMealPlan = async () => {
    if (added) return;
    
    try {
      await addToMealPrepPlan(recipe.id, recipe.servings || 1);
      setAdded(true);
    } catch (error) {
      // Error handling is now done in store
    }
  };

  return (
    <button 
      className={styles.button}
      onClick={handleAddToMealPlan}
      disabled={isLoading || added}
    >
      {isLoading ? 'Adding...' : added ? 'Added to Plan' : 'Add to Meal Plan'}
      {added && <span className={styles.checkmark}>&#10004;</span>}
      {error && <div className={styles.error}>{error}</div>}
    </button>
  );
};

export default AddToMealPlanButton;