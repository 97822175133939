import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDAgdIb2zB2eafLKKA6urMOgu8hpERnnU4",
    authDomain: "kitchen-copilot-93007.firebaseapp.com",
    projectId: "kitchen-copilot-93007",
    storageBucket: "kitchen-copilot-93007.firebasestorage.app",
    messagingSenderId: "689440520289",
    appId: "1:689440520289:web:d7cf078c567f3026ac5220",
    measurementId: "G-7BZMXQPP0B"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);