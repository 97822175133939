import React from 'react';
import './RecipeIngredients.css';
import IngredientBlock from './IngredientBlock.tsx';
import RecipeCheckout from './RecipeCheckout.tsx';
import { Recipe } from '../state/recipeStore.ts';

interface RecipeIngredientsProps {
    recipe: Recipe;
}

const RecipeIngredients: React.FC<RecipeIngredientsProps> = ({ recipe }) => {

    return (
        <div className="container">
            <RecipeCheckout recipe={recipe}/>
            <h3>Shopping List:</h3>
            <ul className="ingredient-list">
                {Object.values(recipe.recipeIngredients).map((ingredient) => {
                    return !!ingredient && (
                        <IngredientBlock
                            key={ingredient.id}
                            recipe_ingredient={ingredient}
                            recipeId={recipe.id}
                        />
                    )
                }
                )}
            </ul>
        </div>
    );
};

export default RecipeIngredients;