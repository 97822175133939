import React, { useState } from 'react';
import { LoadingAnimation } from './CopilotSearch.tsx';
import RecipePreviewModal from './RecipePreviewModal.tsx';
import { useRecipeStore } from '../state/recipeStore.ts';
import LoadingSpinner from '../components/LoadingSpinner.tsx';

interface RecipeOption {
  title: string;
  site_url: string;
  main_site: string;
  rating: number;
  description: string;
}

interface RecipeSelectorProps {
  isLoading: boolean;
  recipeOptions: RecipeOption[];
  onBack: () => void;
}

const RecipeCard: React.FC<{
  recipe: RecipeOption;
  onPreview: () => void;
  onAdd: () => void;
}> = ({ recipe, onPreview, onAdd }) => {
  const cardStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '300px',
    minHeight: '200px',
    padding: '1.5rem',
    backgroundColor: '#ffffff',
    border: '1px solid #e9ecef',
    borderRadius: '6px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    transition: 'all 0.2s ease',
  };

  return (
    <div 
      style={cardStyle}
      onMouseOver={(e) => {
        e.currentTarget.style.transform = 'translateY(-2px)';
        e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.transform = 'none';
        e.currentTarget.style.boxShadow = '0 2px 4px rgba(0,0,0,0.05)';
      }}
    >
      <div style={{
        fontWeight: 'bold',
        marginBottom: '0.5rem',
        fontSize: '1.1rem',
        overflowWrap: 'break-word'
      }}>{recipe.title}</div>
      <div style={{
        fontSize: '0.8rem',
        color: '#666',
        marginBottom: '0.5rem'
      }}>
        {recipe.main_site}{recipe.rating ? ` - Rating: ${recipe.rating}⭐` : ''}
      </div>
      <div style={{
        fontSize: '0.9rem',
        color: '#333',
        lineHeight: '1.4',
        marginBottom: '1rem',
        flex: 1
      }}>
        {recipe.description}
      </div>
      <div style={{
        display: 'flex',
        gap: '0.5rem',
        marginTop: 'auto'
      }}>
        <button 
          style={{
            padding: '0.5rem 1rem',
            backgroundColor: '#e9ecef',
            border: '1px solid #dee2e6',
            borderRadius: '4px',
            cursor: 'pointer',
            flex: 1
          }}
          onClick={onPreview}
        >
          Preview
        </button>
        <button 
          style={{
            padding: '0.5rem 1rem',
            backgroundColor: '#007bff',
            color: '#ffffff',
            border: '1px solid #0056b3',
            borderRadius: '4px',
            cursor: 'pointer',
            flex: 1
          }}
          onClick={onAdd}
        >
          Add to Meal Plan
        </button>
      </div>
    </div>
  );
};

const WebRecipeSelector: React.FC<RecipeSelectorProps> = ({ 
  isLoading, 
  recipeOptions, 
  onBack 
}) => {
  const { addRecipeFromUrl, addRecipe: { loading, currentTaskId } } = useRecipeStore();
  const [showModal, setShowModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');

  const containerStyle: React.CSSProperties = {
    width: '100%', 
    display: 'flex', 
    flexDirection: 'column',
    gap: '0',
    paddingBottom: '160px',
    margin: '0',
    alignItems: 'center',
    position: 'relative',
    height: '100%'
  };

  const backButtonStyle: React.CSSProperties = {
    position: 'sticky',
    top: "64px",
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: '#f8f9fa',
    padding: '0.75rem 0',
    borderBottom: '1px solid #eee',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    cursor: 'pointer',
    userSelect: 'none',
    width: '100%'
  };

  const resultsContainerStyle: React.CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '1rem',
    padding: '4rem 1rem 1rem 1rem',
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    boxSizing: 'border-box'
  };

  return (
    <div style={containerStyle}>
      {loading ? (
        <LoadingSpinner taskId={currentTaskId} />
      ) : (
        <>
          <div 
            onClick={onBack}
            style={backButtonStyle}
          >
            <span>↩️</span>
            <span>Back</span>
          </div>
          <div style={resultsContainerStyle}>
            {recipeOptions.map((recipe, index) => (
              <RecipeCard
                key={index}
                recipe={recipe}
                onPreview={() => {
                  setPreviewUrl(recipe.site_url);
                  setShowModal(true);
                }}
                onAdd={() => {
                  addRecipeFromUrl(recipe.site_url);
                }}
              />
            ))}
          </div>
        </>
      )}
      {showModal && (
        <RecipePreviewModal
          url={previewUrl}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default WebRecipeSelector;