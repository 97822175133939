import { create } from 'zustand';
import { getRecipeSelections, updateRecipeSelections } from '../api/api.ts';
import { RecipeIngredientSelection } from '../api/types.ts';
import { useAuthStore } from './authStore.ts';
import { IngredientOption, Product, useRecipeStore } from './recipeStore.ts';
import { getProductQuantity } from '../utils/PriceCalculation.ts';

interface SelectionStoreState {
  selectionsLoading: Record<number, boolean>;
  selectedIngredientOptions: Record<number, Record<number, number>>;
  selectedProducts: Record<number, Record<number, string>>;
  selectedForPurchase: Record<number, Record<number, boolean>>;
  selectedQuantities: Record<number, Record<number, number>>;
  
  loadSelections: (recipeId: number) => Promise<void>;
  saveSelections: (recipeId: number) => Promise<void>;
  isSelectionsLoading: (recipeId: number) => boolean;
  setSelectedIngredientOption: (
    recipeId: number,
    recipeIngredientId: number,
    ingredientOption: number
  ) => void;
  selectProduct: (params: {
    recipeId: number;
    recipeIngredientId: number;
    ingredientOptionId: number;
    productId: string;
  }) => void;
  getSelectedIngredientOption: (recipeId: number, recipeIngredientId: number) => IngredientOption | undefined;
  getSelectedProduct: (recipeId: number, recipeIngredientId: number) => Product | undefined;
  setSelectedForPurchase: (recipeId: number, recipeIngredientId: number, selected: boolean) => void;
  getSelectedForPurchase: (recipeId: number, recipeIngredientId: number) => boolean;
  setSelectedQuantity: (recipeId: number, recipeIngredientId: number, quantity: number) => void;
  getSelectedQuantity: (recipeId: number, recipeIngredientId: number) => number;
}

export const useSelectionStore = create<SelectionStoreState>((set, get) => ({
  selectionsLoading: {},
  selectedIngredientOptions: {},
  selectedProducts: {},
  selectedForPurchase: {},
  selectedQuantities: {},

  loadSelections: async (recipeId: number) => {
    set(state => ({
      selectionsLoading: {
        ...state.selectionsLoading,
        [recipeId]: true
      }
    }));

    if (get().selectedIngredientOptions[recipeId] && 
    !get().selectionsLoading[recipeId]) {
        return; // Selections already loaded
    }

    let selections: RecipeIngredientSelection[] = [];
    const authToken = await useAuthStore.getState().getValidFirebaseToken()
    if(authToken) {
      try {
        selections = await getRecipeSelections(recipeId, authToken);
      } catch (error) {
        console.error('Failed to load selections:', error);
      }
    }

    const recipeStore = useRecipeStore.getState();
    const recipe = recipeStore.recipes[recipeId];
    
    // If no selections exist, create default selections for all recipe ingredients
    if (selections.length === 0 && recipe?.recipeIngredients) {
      selections = Object.entries(recipe.recipeIngredients).map(([id, recipeIngredient]) => {
        const recipeIngredientId = Number(id);
        const options = recipeStore.getIngredientOptions(recipeId, recipeIngredientId);
        if (!options || options.length === 0) return null;
        
        return {
          recipe_id: recipeId,
          recipe_ingredient_id: recipeIngredientId,
          selected_ingredient_option_id: options[0].ingredient_id,
          selected_product_id: '',
          selected_for_purchase: true,
          quantity: null
        };
      }).filter((selection): selection is RecipeIngredientSelection => selection !== null);
    }

    // First, set all the basic selections
    for (const selection of selections) {
      // Set ingredient option
      get().setSelectedIngredientOption(
        recipeId,
        selection.recipe_ingredient_id,
        selection.selected_ingredient_option_id!
      );

      // Set purchase selection
      get().setSelectedForPurchase(
        recipeId,
        selection.recipe_ingredient_id,
        selection.selected_for_purchase ?? true
      );
    }

    // Fetch all product options first
    await Promise.all(selections.map(selection => 
      useRecipeStore.getState().fetchProductOptions(
        recipeId,
        selection.recipe_ingredient_id,
        selection.selected_ingredient_option_id!
      )
    ));

    // Now set products and calculate quantities
    for (const selection of selections) {
      // Set product if one was selected
      if (selection.selected_product_id) {
        get().selectProduct({
          recipeId,
          recipeIngredientId: selection.recipe_ingredient_id,
          ingredientOptionId: selection.selected_ingredient_option_id!,
          productId: selection.selected_product_id
        });
      }

      // Calculate quantity
      let quantity = selection.quantity;
      if (!quantity && recipe?.recipeIngredients) {
        const recipeIngredient = recipe.recipeIngredients[selection.recipe_ingredient_id];
        const product = get().getSelectedProduct(recipeId, selection.recipe_ingredient_id);
        if (recipeIngredient && product) {
          quantity = getProductQuantity(product, recipeIngredient) || 1;
        }
      }

      // Set the final quantity
      get().setSelectedQuantity(
        recipeId,
        selection.recipe_ingredient_id,
        quantity ?? 1
      );
    }

    set(state => ({
      selectionsLoading: {
        ...state.selectionsLoading,
        [recipeId]: false
      }
    }));
  },

  saveSelections: async (recipeId: number) => {
    const authToken = await useAuthStore.getState().getValidFirebaseToken()
    if (!authToken) {
      console.error('No auth token available');
      return;
    }

    // Do not save selections if they are still loading
    if (get().isSelectionsLoading(recipeId)) {
      return;
    }

    const recipeStore = useRecipeStore.getState();
    const recipe = recipeStore.recipes[recipeId];
    if (!recipe) return;

    const selections: RecipeIngredientSelection[] = [];
    
    Object.entries(recipe.recipeIngredients).forEach(([recipeIngredientId]) => {
      const selected = get().getSelectedIngredientOption(recipeId, Number(recipeIngredientId));
      if (selected) {
        const selectedProduct = get().getSelectedProduct(
          recipeId,
          Number(recipeIngredientId)
        );
        
        selections.push({
          recipe_id: recipeId,
          recipe_ingredient_id: Number(recipeIngredientId),
          selected_ingredient_option_id: selected.ingredient_id,
          selected_product_id: selectedProduct?.id || '',
          selected_for_purchase: get().getSelectedForPurchase(recipeId, Number(recipeIngredientId)),
          quantity: get().getSelectedQuantity(recipeId, Number(recipeIngredientId))
        });
      }
    });


    try {
      await updateRecipeSelections(recipeId, selections, authToken);
    } catch (error) {
      console.error('Failed to save selections:', error);
    }
  },

  isSelectionsLoading: (recipeId: number) => {
    return get().selectionsLoading[recipeId] || false;
  },

  setSelectedIngredientOption: (recipeId: number, recipeIngredientId: number, ingredientOption: number) => {
    set(state => ({
      ...state,
      selectedIngredientOptions: {
        ...state.selectedIngredientOptions,
        [recipeId]: {
            ...state.selectedIngredientOptions[recipeId],
            [recipeIngredientId]: ingredientOption
        }
      }
    }));

    // Fetch product options for the selected ingredient if not already loaded
    if (!useRecipeStore.getState().getProductOptions(recipeId, recipeIngredientId, ingredientOption)) {
        useRecipeStore.getState().fetchProductOptions(recipeId, recipeIngredientId, ingredientOption);
    }
  },

  selectProduct: (params) => {
    const { recipeId, recipeIngredientId, productId } = params;
    set(state => ({
      selectedProducts: {
        ...state.selectedProducts,
        [recipeId]: {
          ...state.selectedProducts[recipeId],
          [recipeIngredientId]: productId
        }
      }
    }));
  },

  getSelectedIngredientOption: (recipeId, recipeIngredientId) => {
    const selectedIngredientId = get().selectedIngredientOptions[recipeId]?.[recipeIngredientId];
    const options = useRecipeStore.getState().getIngredientOptions(recipeId, recipeIngredientId)
    if (!options) return undefined;
    if (!selectedIngredientId) return options[0];
    return options.find(option => option.ingredient_id === selectedIngredientId);
  },

  getSelectedProduct: (recipeId, recipeIngredientId) => {
    const selectedProductId = get().selectedProducts[recipeId]?.[recipeIngredientId];
    const selectedIngredientId = get().getSelectedIngredientOption(recipeId, recipeIngredientId)?.ingredient_id;

    if (!selectedIngredientId) return undefined
    const options = useRecipeStore.getState().getProductOptions(recipeId, recipeIngredientId, selectedIngredientId);
    if (!options) return undefined;
    
    const product = options.find(option => option.id === selectedProductId);
    if (product) return product;
    else {
        return options[0];
    }
  },

  setSelectedForPurchase: (recipeId: number, recipeIngredientId: number, selected: boolean) => {
    set(state => ({
      selectedForPurchase: {
        ...state.selectedForPurchase,
        [recipeId]: {
          ...state.selectedForPurchase[recipeId],
          [recipeIngredientId]: selected
        }
      }
    }));
  },

  getSelectedForPurchase: (recipeId: number, recipeIngredientId: number) => {
    return get().selectedForPurchase[recipeId]?.[recipeIngredientId] ?? true;
  },

  setSelectedQuantity: (recipeId: number, recipeIngredientId: number, quantity: number) => {
    set(state => ({
      selectedQuantities: {
        ...state.selectedQuantities,
        [recipeId]: {
          ...state.selectedQuantities[recipeId],
          [recipeIngredientId]: Math.max(1, quantity)
        }
      }
    }));
  },

  getSelectedQuantity: (recipeId: number, recipeIngredientId: number) => {
    return get().selectedQuantities[recipeId]?.[recipeIngredientId];
  }
}));