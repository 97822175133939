import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

export const NavToggleHeader: React.FC<{
    mode: 'search' | 'copilot';
  }> = ({ mode }) => {
    const navigate = useNavigate();

    const onModeSelect = (selectedMode: 'search' | 'copilot') => {
        if (mode !== selectedMode) {
          if (selectedMode === 'search') {
            navigate('/');
          } else {
            navigate('/inspiration');
          }
        }
      };
    
    return (
      <div style={{
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        zIndex: 1100,
        borderBottom: '1px solid #eee',
        minHeight: '48px',
        padding: '8px 0'
      }}>
        <div style={{
          display: 'flex',
          gap: '0.5rem',
          padding: '0.5rem',
          maxWidth: '600px',
          margin: '0 auto'
        }}>
          <button
            onClick={() => onModeSelect('copilot')}
            style={{
              flex: 1,
              border: 'none',
              background: mode === 'copilot' ? '#f0f0f0' : 'white',
              cursor: 'pointer',
              fontSize: '1rem',
              padding: '0.5rem',
              borderRadius: '4px'
            }}
          >
            Find a Recipe
          </button>
    
          <button
            onClick={() => {
              onModeSelect('search');
              navigate('/recipes');
            }}
            style={{
              flex: 1,
              border: 'none',
              background: mode === 'search' ? '#f0f0f0' : 'white',
              cursor: 'pointer',
              fontSize: '1rem',
              padding: '0.5rem',
              borderRadius: '4px'
            }}
          >
            My Recipes
          </button>
        </div>
      </div>
    );
  };