import React, { useEffect, useState } from 'react';
import { auth } from './firebase.config.ts';
import { User } from 'firebase/auth';
import { useMealPlanStore } from './state/mealPlanStore.ts';
import { useRecipeStore, Recipe } from './state/recipeStore.ts';
import { Link } from 'react-router-dom';
import './MealPlan.css';
import { deleteMealPlanSelection } from './api/api.ts';

const DEFAULT_IMAGE = 'https://via.placeholder.com/300x200?text=No+Recipe+Image';

const extractDomain = (url?: string) => {
  if (!url) return '';
  try {
    const { hostname } = new URL(url);
    return hostname;
  } catch (error) {
    console.error("Invalid URL:", error);
    return url;
  }
};

const SkeletonCard = () => (
  <div className="card-wrapper skeleton-card">
    <div className="recipe-content" style={{ display: 'flex', padding: '15px' }}>
      <div className="skeleton-image pulse"></div>
      <div className="skeleton-metadata">
        <div className="skeleton-line pulse"></div>
        <div className="skeleton-line pulse"></div>
        <div className="skeleton-line pulse"></div>
        <div className="skeleton-line pulse"></div>
      </div>
    </div>
  </div>
);

const MealPlan = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [recipes, setRecipes] = useState<Record<number, Recipe>>({});
  const [deletingIds, setDeletingIds] = useState<number[]>([]);

  const { 
    selections,
    isLoading: mealPlansLoading,
    error: mealPlansError,
    fetchMealPlan: fetchAll,
    removeMealPrepSelection
  } = useMealPlanStore();

  const { fetchRecipe } = useRecipeStore();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        await fetchAll();
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [user, fetchAll]);

  useEffect(() => {
    const loadRecipes = async () => {
      const recipeData: Record<number, Recipe> = {};
      for (const selection of selections) {
        try {
          await fetchRecipe(selection.recipe_id);
          const recipe = useRecipeStore.getState().recipes[selection.recipe_id];
          if (recipe) {
            recipeData[selection.recipe_id] = recipe;
          }
        } catch (err) {
          console.error(`Error fetching recipe ${selection.recipe_id}:`, err);
        }
      }
      setRecipes(recipeData);
    };

    if (selections.length > 0) {
      loadRecipes();
    }
  }, [selections, fetchRecipe]);

  const handleDelete = async (selectionId: number) => {
    try {
      setDeletingIds(prev => [...prev, selectionId]);
      await removeMealPrepSelection(selectionId);
    } catch (err) {
      console.error('Failed to delete selection:', err);
      setError(err instanceof Error ? err.message : 'Failed to delete selection');
    } finally {
      setDeletingIds(prev => prev.filter(id => id !== selectionId));
    }
  };

  return (
    <div style={{ marginTop: '20px' }}>
      {mealPlansLoading && (
        <div style={{ display: 'grid', gap: '20px', maxWidth: '800px', margin: '0 auto' }}>
          {[1, 2, 3].map((i) => (
            <SkeletonCard key={i} />
          ))}
        </div>
      )}
      {mealPlansError && <p style={{ color: 'red' }}>Error: {mealPlansError}</p>}
      {!mealPlansLoading && selections.length > 0 ? (
        <div style={{ display: 'grid', gap: '20px', maxWidth: '800px', margin: '0 auto' }}>
          {selections.map((selection) => {
            const recipe = recipes[selection.recipe_id];
            if (!recipe) return <SkeletonCard key={selection.id} />;
            return (
              <div 
                key={selection.id}
                className="card-wrapper"
                style={{ width: '100%', border: '1px solid #ddd', borderRadius: '8px', position: 'relative' }}
              >
                <Link to={`/recipe-view/${selection.recipe_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <div className="recipe-title">
                    {recipe?.title || `Recipe ${selection.recipe_id}`}
                  </div>
                  <div className="recipe-content" style={{ display: 'flex', padding: '15px' }}>
                    <div className="recipe-image-wrapper" style={{ width: '200px', height: '150px', overflow: 'hidden' }}>
                      <img 
                        src={recipe?.picture_link || DEFAULT_IMAGE}
                        alt={recipe?.title || 'Recipe'}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        onError={(e) => {
                          (e.target as HTMLImageElement).src = DEFAULT_IMAGE;
                        }}
                      />
                    </div>
                    <div className="recipe-metadata" style={{ marginLeft: '15px' }}>
                      <p>Planned Servings: {selection.servings}</p>
                      {recipe && (
                        <>
                          <p>Source: {extractDomain(recipe.source_url)}</p>
                          <p>Preparation: {recipe.preparation_minutes || '?'} mins</p>
                          <p>Cooking: {recipe.cooking_minutes || '?'} mins</p>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete(selection.id!);
                  }}
                  disabled={deletingIds.includes(selection.id!)}
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    padding: '5px 10px',
                    backgroundColor: '#dc3545',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    opacity: deletingIds.includes(selection.id!) ? 0.7 : 1
                  }}
                >
                  {deletingIds.includes(selection.id!) ? 'Removing...' : 'Remove'}
                </button>
              </div>
            );
          })}
        </div>
      ) : (
        !mealPlansLoading && <p>No meal plans selected</p>
      )}
    </div>
  );
};

export default MealPlan;