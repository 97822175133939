// ButtonComponents.tsx
import React from 'react';

// First add the keyframes for the pulse animation
const pulseKeyframes = `
@keyframes pulse {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -200% 0%;
  }
}`;

// Add the style tag to the document
const styleSheet = document.createElement("style");
styleSheet.textContent = pulseKeyframes;
document.head.appendChild(styleSheet);

// Create the Skeleton Button component
const SkeletonButton: React.FC<{style?: React.CSSProperties}> = ({style}) => (
  <div
    style={{
      width: '100px',  // 120px - 20px padding
      height: '100px', // 120px - 20px padding
      padding: '10px',
      background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
      backgroundSize: '200% 100%',
      animation: 'pulse 1.5s ease-in-out infinite',
      borderRadius: '6px',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      alignItems: 'center',
      justifyContent: 'center',
      ...style,
    }}
  >
    <div style={{ height: '2rem', width: '2rem', borderRadius: '4px' }} />
    <div style={{ height: '1rem', width: '80%', borderRadius: '4px' }} />
  </div>
);

interface EmojiButtonProps {
  emoji: string;
  text: string;
  onClick: () => void;
  style?: React.CSSProperties;
}

const EmojiButton: React.FC<EmojiButtonProps> = ({
  emoji,
  text,
  onClick,
  style
}) => {
  // Calculate font size based on longest word length
  const getFontSize = (text: string) => {
    const longestWordLength = text.split(/\s+/).reduce((max, word) => 
      Math.max(max, word.length), 0);
    
    if (longestWordLength <= 11) return '0.9rem';
    if (longestWordLength <= 16) return '0.8rem';
    return '0.7rem';
  };

  return (
    <button
      style={style}
      onClick={onClick}
      onMouseOver={(e) => {
        e.currentTarget.style.backgroundColor = '#f8f9fa';
        e.currentTarget.style.transform = 'translateY(-1px)';
        e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.backgroundColor = '#ffffff';
        e.currentTarget.style.transform = 'none';
        e.currentTarget.style.boxShadow = '0 2px 4px rgba(0,0,0,0.05)';
      }}
    >
      <span style={{ fontSize: '2rem' }}>{emoji}</span>
      <span style={{
        fontSize: getFontSize(text),
        lineHeight: '1.2',
        textAlign: 'center',
      }}>{text}</span>
    </button>
  );
};

// Update the ButtonContainer interface
interface ButtonGridProps {
  buttons: Array<{
    emoji?: string;
    text: string;
    onClick: () => void;
    customWidth?: string;
    customHeight?: string;
  }>;
  isLoading?: boolean;
  skeletonCount?: number;
}

const ButtonContainer: React.FC<ButtonGridProps> = ({ 
  buttons = [],
  isLoading = false,
  skeletonCount = 12
}) => {
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center', 
    gap: '1rem',
    margin: '0.5rem 0 0 0',
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    padding: '1%',
    width: '100%',
    maxWidth: '100%',
  };

  const baseButtonStyle: React.CSSProperties = {
    padding: '1rem',
    backgroundColor: '#ffffff',
    border: '1px solid #e9ecef',
    borderRadius: '6px',
    cursor: 'pointer',
    fontSize: '0.95rem',
    fontWeight: '500',
    color: '#495057',
    transition: 'all 0.2s ease',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    flexShrink: 0,
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  };

  return (
    <div style={containerStyle}>
      {buttons.map((button, index) => (
        <EmojiButton
          key={`button-${index}`}
          emoji={button.emoji || '🔲'}
          text={button.text}
          onClick={button.onClick}
          style={{
            ...baseButtonStyle,
            width: button.customWidth || '120px',
            height: button.customHeight || '120px'
          }}
        />
      ))}

      {isLoading && Array.from({ length: skeletonCount }).map((_, index) => (
        <SkeletonButton
          key={`skeleton-${index}`}
          style={{
            flexShrink: 0,
            width: '120px',
            height: '120px',
            boxSizing: 'border-box'
          }}
        />
      ))}
    </div>
  );
};

export { ButtonContainer, EmojiButton };