import { create } from 'zustand';
import { getTaskStatus as apiGetTaskStatus } from '../api/api.ts';

interface Task {
  taskId: string;
  status: string;
  recipeId?: number;
}

interface TaskStoreState {
  tasks: Record<string, Task>;
  pollTask: (taskId: string) => void;
  clearTask: (taskId: string) => void;
  getTaskStatus: (taskId: string) => string | null;
}

export const useTaskStore = create<TaskStoreState>((set, get) => ({
  tasks: {},

  pollTask: (taskId: string) => {
    const pollInterval = setInterval(async () => {
      try {
        const data = await apiGetTaskStatus(taskId);
        const status = data.status;
        
        set((state) => ({
          tasks: {
            ...state.tasks,
            [taskId]: {
              taskId,
              status,
              recipeId: data.recipe_id
            }
          }
        }));

        if (status === "completed" && data.recipe_id) {
          clearInterval(pollInterval);
          window.location.href = `/recipe-view/${data.recipe_id}`;
        }
      } catch (error) {
        console.error("Error polling task:", error);
        clearInterval(pollInterval);
      }
    }, 500);

    return () => clearInterval(pollInterval);
  },

  clearTask: (taskId: string) => {
    set((state) => ({
      tasks: Object.fromEntries(
        Object.entries(state.tasks).filter(([key]) => key !== taskId)
      )
    }));
  },

  getTaskStatus: (taskId: string) => {
    return get().tasks[taskId]?.status || null;
  }
}));