import React from 'react';
import { useTaskStore } from '../state/taskStore.ts';

interface LoadingSpinnerProps {
  taskId?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ taskId }) => {
  const { getTaskStatus } = useTaskStore();

  const styles = {
    loadingContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100px',
      fontSize: '1.5em',
      color: '#555',
      fontWeight: 'bold',
    },
    spinner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      marginBottom: '10px',
      width: '250px',
      height: '250px',
    },
    ring: {
      position: 'absolute',
      border: '16px dashed #f3f3f3',
      borderTop: '8px dashed #FFDCCF',
      borderRadius: '50%',
      width: '100%',
      height: '100%',
      animation: 'spin 2s linear infinite, travel 1.5s linear infinite',
    },
    mascotImage: {
      width: '150px',
      zIndex: 1,
      animation: 'bounce 1s infinite',
    },
  } as const;

  return (
    <div style={styles.loadingContainer}>
      <div style={styles.spinner}>
        <div style={styles.ring}></div>
        <img 
          src={'/logo512.png'} 
          alt="Mascot stirring ingredients" 
          style={styles.mascotImage}
        />
      </div>
      <p>Loading... {taskId && getTaskStatus(taskId)}</p>
    </div>
  );
};

export default LoadingSpinner;