import React, { useState, useEffect, useCallback } from 'react';
import { FaChevronDown, FaChevronUp, FaShoppingCart, FaRegTimesCircle } from "react-icons/fa";
import styles from './TopProduct.module.css';
import { ProductOptionsList } from './ProductOptionsList.tsx';
import { getProductPriceInfo, getProductQuantity, formatQuantity} from '../utils/PriceCalculation.ts';
import { IngredientOption, RecipeIngredient, useRecipeStore } from '../state/recipeStore.ts';
import { useSelectionStore } from '../state/selectionStore.ts';

interface IngredientBlockProps {
    recipe_ingredient: RecipeIngredient;
    recipeId: number;
}

const IngredientBlock: React.FC<IngredientBlockProps> = ({ recipe_ingredient, recipeId }) => {
    const [expanded, setExpanded] = useState(false);
    
    const { 
        fetchClosestIngredients,
        getIngredientOptions,
        getProductOptions,
        fetchProductOptions,
        loadingStates
    } = useRecipeStore();

    const { 
        selectProduct, 
        setSelectedIngredientOption, 
        isSelectionsLoading, 
        getSelectedIngredientOption, 
        getSelectedProduct, 
        saveSelections,
        setSelectedForPurchase,
        getSelectedForPurchase,
        setSelectedQuantity,
        getSelectedQuantity
    } = useSelectionStore();

    const handleProductSelection = useCallback((product) => {
        const selectedIngredient = getSelectedIngredientOption(recipeId, recipe_ingredient.id);
        if(selectedIngredient && selectedIngredient.ingredient_id) {
            selectProduct({
                recipeId: recipeId,
                recipeIngredientId: recipe_ingredient.id,
                ingredientOptionId: selectedIngredient?.ingredient_id,
                productId: product.id
            });
            saveSelections(recipeId);
        }
    }, [recipe_ingredient, selectProduct, getSelectedIngredientOption, recipeId]);

    useEffect(() => {
        fetchClosestIngredients(recipeId, recipe_ingredient.id);
    }, [fetchClosestIngredients, recipeId, recipe_ingredient.id]);

    const selectionsLoading = isSelectionsLoading(recipeId);
    const ingredientLoading = loadingStates[recipeId]?.[recipe_ingredient.id]?.ingredients;
    const productLoading = loadingStates[recipeId]?.[recipe_ingredient.id]?.products;
    const anyProductLoading = Object.values(loadingStates[recipeId]?.[recipe_ingredient.id]?.products || {}).some(loading => loading);

    if (selectionsLoading || ingredientLoading || anyProductLoading || getSelectedIngredientOption(recipeId, recipe_ingredient.id) == undefined) {
        return (
            <li style={{ 
                marginBottom: '15px', 
                padding: '10px', 
                border: '1px solid lightgray', 
                borderRadius: '5px'
            }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '10px', flex: 1 }}>
                        {/* Product Image Skeleton */}
                        <div className={`${styles.skeletonItem}`} style={{ 
                            width: '50px', 
                            height: '50px', 
                            borderRadius: '50%',
                        }} />
                        
                        <div>
                            {/* Text Content Skeleton */}
                            <div className={`${styles.skeletonItem}`} style={{
                                width: '200px',
                                height: '20px',
                                marginBottom: '8px',
                            }} />
                            <div className={`${styles.skeletonItem}`} style={{
                                width: '150px',
                                height: '16px',
                            }} />
                        </div>
                    </div>
    
                    {/* Price Pill Skeleton */}
                    <div className={`${styles.skeletonItem}`} style={{
                        width: '80px',
                        height: '32px',
                        borderRadius: '16px'
                    }} />
                </div>
            </li>
        );
    }

    const selectedProduct = getSelectedProduct(recipeId, recipe_ingredient.id);


    const ingredientOptions = getIngredientOptions(recipeId, recipe_ingredient.id);
    const selectedIngredient = getSelectedIngredientOption(recipeId, recipe_ingredient.id);
    const productOptions = selectedIngredient ? getProductOptions(recipeId, recipe_ingredient.id, selectedIngredient.ingredient_id) : [];

    const handleExpandToggle = () => {
        setExpanded(prevState => !prevState);
    };

    const handleIngredientSelect = (e) => {
        const selectedName = e.target.value;
        const selectedOption = ingredientOptions.find(option => option.ingredient_name === selectedName) as IngredientOption;
        setSelectedIngredientOption(recipeId, recipe_ingredient.id, selectedOption.ingredient_id);
        fetchProductOptions(
            recipeId,
            recipe_ingredient.id,
            selectedOption.ingredient_id
        );
        saveSelections(recipeId);
    };

    const handleDropdownClick = () => {
        if (!ingredientOptions.length) {
            fetchClosestIngredients(recipeId, recipe_ingredient.id);
        }
    };

    const ProductImage = ({ imageUrl, altText }) => (
        <div style={{ width: '50px', height: '50px', overflow: 'hidden', borderRadius: '50%' }}>
            <img src={imageUrl} alt={altText} style={{ width: '100%', height: '100%' }} />
        </div>
    );

    const IngredientSelect = ({ selectedIngredient, ingredientOptions, handleDropdownChange, handleDropdownClick }) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <div style={{ flex: 1, minWidth: 0 }}>
            <select
                value={selectedIngredient.ingredient_name || ''}
                onChange={handleDropdownChange}
                onClick={handleDropdownClick}
                style={{ 
                    fontSize: '16px', 
                    border: '1px solid lightgray', 
                    borderRadius: '5px', 
                    padding: '5px',
                    width: '100%',
                    maxWidth: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}
            >
                {ingredientOptions ? (
                    ingredientOptions.map(option => (
                        <option key={option.ingredient_id} value={option.ingredient_name}>
                            {option.ingredient_name}
                        </option>
                    ))
                ) : (
                    <option value="">{selectedIngredient.ingredient_name}</option>
                )}
            </select>
            </div>
        </div>
    );

    const ProductSelectionHeader = ({ quantityNeeded, topProduct, handleExpandToggle, expanded }) => {
        return (
            <div style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                <button onClick={handleExpandToggle} style={{ background: 'none', border: 'none', cursor: 'pointer', padding: '0', marginRight: '5px' }}>
                    {expanded ? <FaChevronUp size={20} /> : <FaChevronDown size={20} />}
                </button>
                {quantityNeeded && <span style={{ fontWeight: 'bold', paddingRight: '5px' }}>{quantityNeeded}</span>}
                <span>{topProduct.title}</span>
            </div>
        );
    };

    const priceInfo = getProductPriceInfo(selectedProduct!, recipe_ingredient);
    const quantityNeeded = getProductQuantity(selectedProduct!, recipe_ingredient);
    const priceMultiplier = getSelectedForPurchase(recipeId, recipe_ingredient.id) 
        ? getSelectedQuantity(recipeId, recipe_ingredient.id)
        : (quantityNeeded ? quantityNeeded : 1);

    return (
        <li style={{ marginBottom: '15px', padding: '10px', border: '1px solid lightgray', borderRadius: '5px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '10px', flex: 1 }}>
                    {selectedProduct && selectedProduct.image_url && <ProductImage imageUrl={selectedProduct.image_url} altText={selectedProduct.title} />}
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px', flexWrap: 'wrap' }}>
                            {recipe_ingredient.raw_text && <span>{recipe_ingredient.raw_text} | </span>}
                            {recipe_ingredient.quantity && <span>{formatQuantity(recipe_ingredient.quantity)} </span>}
                            {recipe_ingredient.unit && <span>{recipe_ingredient.unit} of </span>}
                            {recipe_ingredient.ingredient_name && (
                                selectedIngredient && (
                                    <IngredientSelect
                                        selectedIngredient={selectedIngredient}
                                        ingredientOptions={ingredientOptions}
                                        handleDropdownChange={handleIngredientSelect}
                                        handleDropdownClick={() => handleDropdownClick()}
                                    />
                                )
                            )}
                            {recipe_ingredient.preparation && <span> ({recipe_ingredient.preparation})</span>}
                        </div>
                        {selectedProduct && selectedProduct.title && <ProductSelectionHeader topProduct={selectedProduct} quantityNeeded={quantityNeeded} handleExpandToggle={handleExpandToggle} expanded={expanded} />}
                    </div>
                </div>
    
                {selectedProduct && selectedProduct.price && (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                            <button 
                                onClick={() => {
                                    setSelectedForPurchase(recipeId, recipe_ingredient.id, !getSelectedForPurchase(recipeId, recipe_ingredient.id));
                                    saveSelections(recipeId);
                                }}
                                style={{ 
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    opacity: getSelectedForPurchase(recipeId, recipe_ingredient.id) ? 1 : 0.5
                                }}
                            >
                                <div style={{ position: 'relative' }}>
                                    <FaShoppingCart size={20} />
                                    {!getSelectedForPurchase(recipeId, recipe_ingredient.id) && (
                                        <FaRegTimesCircle 
                                            size={14} 
                                            style={{ 
                                                position: 'absolute',
                                                top: -4,
                                                right: -4,
                                                background: 'white',
                                                borderRadius: '50%'
                                            }}
                                        />
                                    )}
                                </div>
                            </button>
                            {getSelectedForPurchase(recipeId, recipe_ingredient.id) && (
                                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <span style={{ color: '#666' }}>x</span>
                                    <input
                                        type="number"
                                        value={getSelectedQuantity(recipeId, recipe_ingredient.id)}
                                        min="1"
                                        onChange={(e) => {
                                            const newQuantity = Math.max(1, parseInt(e.target.value) || 1);
                                            setSelectedQuantity(recipeId, recipe_ingredient.id, newQuantity);
                                            saveSelections(recipeId);
                                        }}
                                        style={{ 
                                            width: '40px',
                                            padding: '2px 4px',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            textAlign: 'center'
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'e' || e.key === '-' || e.key === '+') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className={styles.pricePill} 
                            style={{ 
                                maxWidth: 'calc(100% - 10px)', 
                                overflow: 'auto',
                                opacity: getSelectedForPurchase(recipeId, recipe_ingredient.id) ? 1 : 0.5
                            }}>
                            <div className={styles.price}>
                                ${ (Math.round(selectedProduct.price * 100 * priceMultiplier) / 100).toFixed(2) }
                            </div>
                            {priceInfo && (
                                <div className={styles.priceDetails}>
                                    <div>Used: ${ priceInfo.priceOfQuantityUsed.toFixed(2) }</div>
                                    <div>Leftover ${ priceInfo.priceOfQuantityRemaining.toFixed(2) }</div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {expanded && selectedIngredient && <ProductOptionsList recipeIngredient={recipe_ingredient} selectedProduct={selectedProduct} selectedIngredient={selectedIngredient} productOptions={productOptions} handleProductSelection={handleProductSelection} />}
        </li>
    );
}

export default IngredientBlock;